import jQuery from 'jquery/dist/jquery';
import * as bootstrap from 'bootstrap'
import Utils from '../../../utils'
import DefaultController from "../../defaultController";
import ShipmentEntity from "../entity";
import Swal from 'sweetalert2';

export default class ShipmentList extends DefaultController {
    //@ts-ignore
    private datatables: any[];
    private editModal: any;
    private receivedModal: any;
    private mode: any;

    async init() {
        this.entity = "shipments";
        this.datatables = [];
        this.editModal = new bootstrap.Modal((document.querySelector("#editShipment") as HTMLElement));
        this.receivedModal = new bootstrap.Modal((document.querySelector("#receivedShipment") as HTMLElement));
        this.mode = (document.querySelector(".card[data-mode]") as HTMLElement).getAttribute("data-mode");

        (document.querySelectorAll(".tab-pane[data-location-id]") as NodeListOf<HTMLElement>).forEach((elem) => {
            this.createTable(elem);
        });
        await super.init();
    }

    getEntityData(elem: any) {
        const location_id = (document.querySelector(".tab-pane.active.show") as HTMLElement).getAttribute("data-location-id")
        return {...ShipmentEntity.getEntityData(elem), location_id: location_id}
    }

    bindListeners() {
        jQuery(".tab-content").delegate(".delete-record", "click", async (e: any) => {
            const confirm = await Swal.fire({
                title: 'Sind Sie sicher?',
                text: "Das Löschen kann nicht rückgängig gemacht werden!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ja, löschen',
                customClass: {
                    confirmButton: 'btn btn-primary me-3',
                    cancelButton: 'btn btn-label-secondary'
                },
                buttonsStyling: false
            })
            if(confirm.value === true) {
                const id = e.currentTarget.getAttribute("data-id")
                const r = await Utils.entity.destroy(id, this.entity)
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('shipment.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                } else {
                    this.toastr.error(`${Utils.translate('shipment.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                }
                this.datatables.forEach((table: any) => {
                    table.ajax.reload();
                })
            }
        });


        jQuery(".tab-content").delegate(".edit-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const requestData = await Utils.entity.get(id, this.entity)
            if (requestData.status === 200) {
                const data = requestData.data[0]
                const form = (document.querySelector("#editShipmentForm") as HTMLFormElement);
                await Utils.updateElements(data, '', form);
                (form.querySelectorAll(".hide_on_input") as NodeListOf<HTMLElement>).forEach((el) => {
                    el.classList.remove("d-none");
                });
                this.editModal.show();
            }
        });
        jQuery(".tab-content").delegate(".duplicate-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const requestData = await Utils.entity.duplicate(id, this.entity)
            if (requestData.status === 200) {
                this.datatables.forEach((table: any) => {
                    table.ajax.reload();
                })
            }

        });

        jQuery(".tab-content").delegate(".mode-change-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const requestData = await Utils.entity.get(id, this.entity)
            if (requestData.status === 200) {
                const data = requestData.data[0]
                const form = (document.querySelector("#receivedShipmentForm") as HTMLFormElement);
                await Utils.updateElements(data, '', form);

                (form.querySelectorAll(".hide_on_input") as NodeListOf<HTMLElement>).forEach((el) => {
                    el.classList.add("d-none");
                });
                this.receivedModal.show();
            }
        });
        (document.querySelector("#addNewShipmentForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#addNewShipmentForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    this.datatables.forEach((table: any) => {
                        table.ajax.reload();
                    })
                    const bsElem = bootstrap.Offcanvas.getInstance((document.querySelector("#offcanvasAddShipment") as HTMLElement))
                    if (bsElem) {
                        bsElem.hide();
                    }
                    elem.reset();
                    this.toastr.success(`${Utils.translate('shipment.name')} ${Utils.translate('generic.messages.created')}`, `${Utils.translate('generic.success')}`)
                }
            }
        });

        (document.querySelector("#editShipmentForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#editShipmentForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    this.datatables.forEach((table: any) => {
                        table.ajax.reload();
                    })
                    this.editModal.hide();
                    this.toastr.success(`${Utils.translate('shipment.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                }
            }
        });

        (document.querySelector("#receivedShipmentForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#receivedShipmentForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                let articles = (elem.querySelector("#shipment_articles") as HTMLInputElement).value;
                articles += `\n\n${(new Date().toLocaleString("de-DE", {year: 'numeric', month: 'numeric', day: 'numeric', minute: "2-digit", hour: "2-digit"}))}\n`;
                articles += (elem.querySelector("#shipment_new_articles") as HTMLInputElement).value;
                let status = (elem.querySelector("#shipment_status option:checked") as HTMLInputElement).value;
                console.log("status", status)
                const r = await Utils.entity.upsert({...this.getEntityData(elem), articles: articles, status: status}, this.entity)
                if (r.status === 200) {
                    this.datatables.forEach((table: any) => {
                        table.ajax.reload();
                    })
                    this.receivedModal.hide();
                    this.toastr.success(`${Utils.translate('shipment.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                }
            }
        })
    }

    createTable(elem: any) {
        const locationId = elem.getAttribute("data-location-id")
        const table = elem.querySelector("table")
        let buttons = [
            {
                text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')}</span>`,
                className: 'dt-button add-new btn btn-primary m-2',
                attr: {
                    'data-bs-toggle': 'offcanvas',
                    'data-bs-target': '#offcanvasAddShipment'
                }
            }
        ];
        let columns = [
            {data: 'ticket_number'},
            {data: 'receiver_name'},
            {data: 'receiver_company'},

            {data: 'logistics_company'},
            {data: 'tracking_number'},

            {data: 'uuid'},

            {data: 'status'},
            {data: 'uuid'},
        ];
        let columnDefs = [
            {
                targets: 5,
                render: (data: any, type: any, full: any, meta: any) => {
                    let dateField = new Date(Date.parse(full.valid_to));
                    if (full.status === "PLANNED") {
                        dateField = new Date(Date.parse(full.valid_from))
                    }
                    return `<span style="display:none">${dateField.getTime()}</span>${dateField.toLocaleString("de-DE", {year: 'numeric', month: 'numeric', day: 'numeric', minute: "2-digit", hour: "2-digit"})}`;
                },
            },
            {
                targets: 6,
                render: (data: any, type: any, full: any, meta: any) => {
                    return this.transformStatus(data);
                },
            },
            {
                targets: 7,
                searchable: false,
                orderable: false,
                render: (data: any, type: any, full: any, meta: any) => {
                    if (this.mode === "history") {
                        return "";
                    }
                    let modechange = "";
                    if (full.status === "PLANNED" || full.status === "PARTLYDELIVERED") {
                        modechange = `<a href="#" data-id="${data}" data-new-mode="DONE" class="text-body mode-change-record" title="Wareneingang buchen">
                                    <i class="ti ti-package-import ti-sm me-2"></i>
                                  </a>`
                    }
                    return `<div class="d-flex align-items-center justify-content-end">
                                  ${modechange}
                                  <a href="#" data-id="${full.uuid}" class="text-body duplicate-record">
                                    <i class="ti ti-copy ti-sm me-2"></i>
                                  </a>
                                  <a href="#" data-id="${data}" class="text-body edit-record">
                                    <i class="ti ti-edit ti-sm me-2"></i>
                                  </a>
                                  <a href="#" data-id="${data}" class="text-body delete-record">
                                    <i class="ti ti-trash ti-sm mx-2"></i>
                                  </a>
                                </div>`
                },
            }
        ];
        if (this.mode === "history") {
            buttons = [
                {
                    // @ts-ignore
                    extend: 'csv',
                    charset: 'utf-8',
                    text: 'Export',
                    className: 'dt-button add-new btn btn-primary m-2',
                    exportOptions: {
                        columns: [0, 1, 2, 3, 4, 5, 6, 7],
                    }
                }]
            columns = [
                {data: 'ticket_number'},
                {data: 'receiver_name'},
                {data: 'receiver_company'},

                {data: 'logistics_company'},
                {data: 'tracking_number'},

                {data: 'uuid'},

                {data: 'uuid'},
                {data: 'uuid'},
            ];
            columnDefs = [
                {
                    targets: 5,
                    render: (data: any, type: any, full: any, meta: any) => {
                        let dateField = new Date(Date.parse(full.registered_at));
                        return `<span style="display:none">${dateField.getTime()}</span>${dateField.toLocaleString("de-DE", {year: 'numeric', month: 'numeric', day: 'numeric', minute: "2-digit", hour: "2-digit"})}`;
                    },
                },
                {
                    targets: 6,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return full.registerer ? full.registerer.name : "---";
                    },
                },
                {
                    targets: 7,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return full.articles.replaceAll("\n\n", "<hr />").replaceAll("\n", "<br />");
                    },
                }
            ];
        }
        const datatable = jQuery(table).DataTable({
            initComplete: () => {
                jQuery(elem).find(".loading-body").addClass("d-none")
                jQuery(elem).find(".table-responsive").removeClass("d-none")
            },
            ajax: `/api/v1/shipments?location_id=${locationId}&mode=${this.mode}`,
            columns: columns,
            columnDefs: columnDefs,
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-2 mt-3"p>' +
                '>',

            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: buttons
        });
        this.datatables.push(datatable);
    }

    transformStatus(status: string) {
        if (status === "PLANNED") {
            return `<span class="badge text-bg-success">${Utils.translate("shipment.status.planned")}</span>`
        }else if (status === "PARTLYDELIVERED") {
            return `<span class="badge text-bg-warning">${Utils.translate("shipment.status.partly")}</span>`
        }else if (status === "DONE") {
            return `<span class="badge text-bg-primary">${Utils.translate("shipment.status.done")}</span>`
        }
    }

    transformDateToInput(date: string) {
        const d = new Date(Date.parse(date))
        return (new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString()).slice(0, -1)
    }
}